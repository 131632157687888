var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { add, isBefore } from "date-fns";
export class TokenService {
    get adfsPath() {
        var _a;
        const isDev = !!((_a = window.location.origin.match(/(https:\/\/dev-)|(http:\/\/128)/)) === null || _a === void 0 ? void 0 : _a.length);
        const adfsHost = `https://${isDev ? "dev-" : ""}adfs.finanz.tuwien.ac.at`;
        const clientDev = "18bf9cd7-0140-4e8b-a898-2567d73de39c";
        const clientProd = "2ba91a7d-0118-4d98-83c9-15ff32ba978a";
        return `${adfsHost}/adfs/oauth2/authorize?client_id=${isDev ? clientDev : clientProd}&response_type=code+token&scope=openid`;
    }
    get authEndpoint() {
        return `${this.adfsPath}&redirect_uri=${encodeURIComponent(window.location.href)}`;
    }
    constructor() {
        this.storedTokens = [];
    }
    handleParams() {
        let params = new URLSearchParams();
        //TODO: update adfs mit Harald, passe das dann an when done
        //adfs 2019 
        if (location.href.includes("?"))
            params = new URL(location.href.replace("#", "")).searchParams; //there is a # that would break searchParams (params after # are ignored)                   
        //adfs 2022 (haben sich geändert - zeichen, dass adfs noch weiterentwickelt wird)
        if (location.href.includes("#code"))
            params = new URL(location.href.replace("#code", `${location.href.includes("?") ? "&" : "?"}code`)).searchParams;
        //wenn adfs die redirect-uri mit params an den user zurückschickt, entferne die adfs-parms (sodas etwaige app-spezifischen params übrig bleiben)
        if (params.size) {
            ["client-request-id", "token_type", "expires_in", "access_token", "scope", "error_description", "code"]
                .forEach(p => params.delete(p));
            history.pushState(null, "", location.origin + location.pathname + (params.size ? "?" + params.toString() : ""));
        }
    }
    get(resource) {
        return new Promise((res, rej) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            let storedToken = this.storedTokens.find(obj => obj.resource === resource);
            if (storedToken && isBefore(new Date(), storedToken.validUntil)) {
                res(storedToken.value);
                return;
            }
            this.handleParams();
            this.storedTokens = this.storedTokens.filter(obj => obj.resource !== resource);
            const response = yield fetch(this.authEndpoint + '&response_mode=form_post&resource=' + resource, { headers: new Headers({ 'content-type': 'application/json' }), credentials: "include" });
            const text = yield response.text(); //!ACHTUNG! wenn cross-site-cookies deaktiviert sind (firefox-default-setting oder chrome-anonymous), dann bekommt man hier immer wieder nur das login-html zurück
            const tokenHtml = new DOMParser().parseFromString(text, "text/html");
            const tokenValue = (_b = (_a = tokenHtml.getElementsByName("access_token")) === null || _a === void 0 ? void 0 : _a.item(0)) === null || _b === void 0 ? void 0 : _b.value;
            const errorValue = (_d = (_c = tokenHtml.getElementsByName("error_description")) === null || _c === void 0 ? void 0 : _c.item(0)) === null || _d === void 0 ? void 0 : _d.value;
            //meistens loop detection (zu viele requests (>4) innerhalb 20 sekunden - ADFS-Defaults)
            //ref: https://learn.microsoft.com/en-us/windows-server/identity/ad-fs/troubleshooting/ad-fs-tshoot-loop
            //HTTP-Status 429 (too many requests) wird in entrypoint-component gehandled
            if (errorValue) {
                rej(errorValue);
                return;
            }
            if (!tokenValue)
                window.location.replace(this.authEndpoint);
            this.storedTokens.push({
                resource: resource,
                value: tokenValue,
                validUntil: add(new Date(), { seconds: +tokenHtml.getElementsByName("expires_in").item(0).value })
            });
            res(tokenValue);
        }));
    }
}
