var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TokenService } from "tokenservice";
const params = new URL(document.location.toString()).searchParams;
const powerHost = "https://power.finanz.tuwien.ac.at/Reports";
const folder = params.get("rep") || "powerbi";
const report = params.get("pbi") || "Dashboard";
const reportParams = params.get("pbip") || null;
document.getElementById("iframe").src = `${powerHost}/${folder}/Produktiv/${report}?rs:Command=Render&rs:embed=true&${reportParams}`;
//dev-insight geht nicht solange es kein dev-power gibt!
function GetHeader() {
    return new Promise((res, rej) => __awaiter(this, void 0, void 0, function* () {
        const apiUrl = `https://${window.location.href.includes("dev") ? "dev-" : ""}api.finanz.tuwien.ac.at`;
        let headerHtml = yield fetch(`${apiUrl}/common/FinanceHeader`, {
            headers: new Headers({
                'content-type': 'application/json',
                'authorization': 'Bearer ' + (yield new TokenService().get(apiUrl))
            }),
            credentials: "include"
        });
        const outputDiv = document.getElementById("header");
        outputDiv.innerHTML = yield headerHtml.text();
    }));
}
document.body.onload = () => {
    GetHeader();
    // setInterval(async ()=> {
    //     await fetch(powerHost, {method: "POST", credentials: "include"})
    // }, 110000) //is this bug still present? currently testing with lunterra
};
